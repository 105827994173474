.App {
  text-align: center;
}

.paddin-top {
  padding-top: 38px;
}

.paddin-bottom{
  padding-bottom: 40px;
}

.max{
  width: max-content;
}

.internal-page-wrapper {
  margin-top: 100px;
  padding: 100px 0;
}

.structure-wrapper{
  background-size: initial;
  height: 580px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fbfbfd;
  text-align: center;
  padding: 65px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Meadia Query */
@media (min-width: 768px) {
  .structure-wrapper {
  }
}
